<template>
    <div class="box shadow">
        <Toaster ref="toaster" />
        <div class="box-header">
            <h3 class="box-title d-flex align-items-center">
                Ajouts de keywords FR
                <img src="/assets/img/lang/fr_fr.png" style="width: 30px" class="ml-2" alt="[FR]" />
            </h3>
            <h6>Format : mot-clé, url, position, volume</h6>
            <div class="form-group">
                <textarea v-model="keywords.fr" class="form-control" rows="6" />
            </div>
        </div>
        <div class="box-header">
            <h3 class="box-title d-flex align-items-center">
                Ajouts de keywords EN
                <img src="/assets/img/lang/com_en.png" style="width: 30px" class="ml-2" alt="[FR]" />
            </h3>
            <h6>Format : mot-clé, url, position, volume</h6>
            <div class="form-group">
                <textarea v-model="keywords.en" class="form-control" rows="6" />
            </div>
        </div>
        <button class="btn btn-secondary mt-5" :disabled="disabled" @click.prevent="sendKeywords">
            <i class="fas fa-check-circle mr-2" />Ajouter les mots-clés
        </button>
    </div>
</template>

<script>
    export default {
        name: 'AddKeywords',
        title: 'global.addkeywords',
        data() {
            return {
                disabled: false,
                keywords: {
                    en: '',
                    fr: ''
                }
            }
        },
        computed: {
            keywordsFr() {
                if (this.keywords.fr !== '') {
                    let keywords = this.keywords.fr.split('\n').map(keywordList => {
                        let [keyword, url, position, volume] = keywordList.split(',')
                        return {
                            keyword: keyword,
                            url: url,
                            position: position,
                            volume: volume
                        }
                    })
                    return keywords
                } else {
                    return null
                }
            },
            keywordsEn() {
                if (this.keywords.en !== '') {
                    let keywords = this.keywords.en.split('\n').map(keywordList => {
                        let [keyword, url, position, volume] = keywordList.split(',')
                        return {
                            keyword: keyword,
                            url: url,
                            position: position,
                            volume: volume
                        }
                    })
                    return keywords
                } else {
                    return null
                }
            }
        },
        methods: {
            sendKeywords() {
                this.disabled = true
                let keywords = {}
                if (this.keywordsFr) {
                    keywords.FR = this.keywordsFr
                }
                if (this.keywordsEn) {
                    keywords.EN = this.keywordsEn
                }
                this.axios
                    .post('/admin/sites/keywords', keywords)
                    .then(response => {
                        this.$refs.toaster.fire(
                            'success',
                            `${response.data.addedKeywords} mot(s)-clé(s) ajouté(s) & ${response.data.updatedKeywords} mot(s)-clé(s) mis à jour`
                        )
                        this.keywords.fr = ''
                        this.keywords.en = ''
                    })
                    .catch(error => {
                        this.$refs.toaster.fire('error', `${error}`)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            }
        }
    }
</script>

<style></style>
